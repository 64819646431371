<template>
  <div class="item_list">
    <van-nav-bar
      title="库存详情"
      left-arrow
      safe-area-inset-top
      @click-left="navBack"
    />
    <van-pull-refresh
      v-model="refreshing"
      class="fixheight"
      @refresh="onRefresh"
    >
      <item-card-goods-store
        :goods="goods.goods"
        :store="goods.store"
        :store-in="goods.storeIn"
        :store-out="goods.storeOut"
        class="class_item_card"
      />
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        offset="100"
        class="class_item_detail"
        @load="onListLoad"
      >
        <van-cell title="库存记录" value="" class="class_item_detail_title" />
        <!-- <item-inventory-detail
          v-for="(item, index) in items"
          :key="index"
          :name="item.type"
          :time="item.time"
          :store="item.store"
          :sn="item.sn"
          :num="item.num"
        /> -->
        <div v-for="(item, index) in groupitems" :key="index">
          <van-cell
            :title="item.time"
            value=""
            class="class_item_detail_cell"
          />
          <div>
            <item-inventory-detail
              v-for="(subitem, subindex) in item.item"
              :key="subindex"
              :name="subitem.type"
              :time="subitem.time"
              :store="subitem.store"
              :sn="subitem.sn"
              :num="subitem.num"
              :active="index === 0 && subindex === 0"
            />
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  NavBar,
  List,
  Cell,
  CellGroup,
  Field,
  Form,
  Grid,
  GridItem,
  PullRefresh,
  ImagePreview
} from 'vant'
import ItemCardGoodsStore from '@/components/item-card-goods-store'
import ItemInventoryDetail from '@/components/item-inventory-detail'
import asyncwait from '@/mixins/asyncwait'
import handleNetError from '@/mixins/checklogin'
// eslint-disable-next-line no-unused-vars
import { getStoreDetail, getStoreLogList } from '@/api/inventory'
export default {
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Form.name]: Form,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [ImagePreview.name]: ImagePreview,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [ItemCardGoodsStore.name]: ItemCardGoodsStore,
    [ItemInventoryDetail.name]: ItemInventoryDetail
  },
  mixins: [asyncwait, handleNetError],
  props: {
    id: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      refreshing: false,
      pageIndex: 1,
      finished: false,
      items: [],
      groupitems: [],
      goods: {}
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getDetail()
  },
  methods: {
    toggleFilterModal() {},
    navBack() {
      this.$router.go(-1)
    },
    onPpReset() {
      this.popupshow = false
      console.log('onPpReset')
    },
    onSubmit() {
      console.log('onSubmit')
    },
    onCancel() {
      console.log('onCancel')
      this.$router.go(-1)
    },
    onConfirm() {
      console.log('onConfirm')
    },
    mapLoction(arr, newArr = []) {
      // let newArr = []
      arr.forEach(item => {
        let index = -1
        let alreadyExists = newArr.some((newAddress, j) => {
          if (item.time.substring(0, 7) === newAddress.time) {
            index = j
            return true
          }
        })
        if (!alreadyExists) {
          newArr.push({
            time: item.time.substring(0, 7),
            item: [item]
          })
        } else {
          newArr[index].item.push(item)
        }
      })
      console.log(newArr)
      return newArr
    },
    async getDetail() {
      try {
        const res = await getStoreDetail({
          goods_id: this.id
        })
        if (res.status !== 1) throw res.msg
        console.log(res)
        this.goods = {
          goods: res.data.goods,
          store: res.data.store,
          storeIn: res.data.int,
          storeOut: res.data.out
        }
        console.log('getStoreDetail cp')
      } catch (err) {
        console.log(err)
      }
    },
    async onRefresh() {
      // 清空列表数据
      this.items = []
      this.groupitems = []
      this.finished = false
      this.refreshing = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.pageIndex = 1
      await this.onListLoad()
    },
    async onListLoad() {
      console.log('callload')
      await this.asyncWait(300)
      try {
        const res = await getStoreLogList({
          pageIndex: this.pageIndex,
          pageSize: 10,
          goods_id: this.id
        })
        if (res.status !== 1) throw res.msg
        this.pageIndex++
        console.log(res)
        this.groupitems = this.mapLoction(res.data, this.groupitems)
        this.items = this.items.concat(res.data)
        this.loading = false
        if (res.data.length < 10) {
          this.finished = true
        }
        console.log('getStoreDetail cp')
      } catch (err) {
        this.loading = false
        this.error = true
        console.log(err)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.mt-10 {
  margin-top: 10px;
}
.m-16 {
  margin: 16px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-60i {
  margin-bottom: 60px !important;
}
.item_list {
  background-color: #f5f5f5;
}
.info_main {
  margin: 10px 16px;
  overflow: hidden;
  border-radius: 6px;
}
.class_item_card {
  width: calc(100vw - 10px);
  margin: 5px;
}
.class_item_detail {
  width: calc(100vw - 10px);
  margin: 5px;
  &_title {
    position: sticky;
    top: 0;
  }
  &_cell {
    position: sticky;
    top: 0;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    opacity: 1;
  }
}
.info_comment {
  margin: 10px 16px;
  overflow: hidden;
  border-radius: 6px;
}
.pic_zone {
  margin: 14px;
}
.fixheight {
  height: calc(100vh - 46px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.info_comment_imgs {
  width: 153px;
  height: 110px;
  border: 1px dashed #999999;
}
</style>
