<template>
  <div class="item_card_H_wrap" :class="{ active: active }" @click="OnClick">
    <div class="item_desc_time">
      <span class="item_desc_time_s1">{{ timec1 }}</span>
      <span class="item_desc_time_s2">{{ timec2 }}</span>
    </div>
    <div class="item_desc_line">
      <van-icon name="arrow-up" class-prefix="my-icon" />
    </div>
    <div class="item_desc">
      <div class="item_desc_main">
        <span
          class="item_desc_main_name"
          :class="skeleton ? 'class_skeleton' : ''"
        >
          {{ name }}
        </span>
        <span class="item_desc_sub_store">库存：{{ store }}吨</span>
      </div>
      <div class="item_desc_sub" :class="skeleton ? 'class_skeleton' : ''">
        <span
          v-if="sn"
          class="item_desc_main_sn"
          :class="skeleton ? 'class_skeleton' : ''"
        >
          订单编号 : {{ sn }}
        </span>
      </div>
    </div>
    <div class="item_num" :class="num > 0 ? 'cr' : 'cg'">
      {{ num }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemInventoryDetail',
  props: {
    goods: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: '2020-01-01'
    },
    store: {
      type: Number,
      default: 0
    },
    sn: {
      type: String,
      default: undefined
    },
    num: {
      type: Number,
      default: 0
    },
    skeleton: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    timec1() {
      return this.time.substr(5, 5)
    },
    timec2() {
      return this.time.substr(11, 5)
    }
  },
  methods: {
    OnClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_card_H_wrap {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 5px;
  // margin: 1px;
  background-color: #ffffff;
}
.item_desc_time {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  white-space: pre-wrap;
  opacity: 1;
  &_s2 {
    font-size: 10px;
  }
}
.item_desc_line {
  position: relative;
  width: 12px;
  height: 12px;
  margin: 0 8px 0 14px;
  font-size: 10px;
  color: #a3a3a3;
  .my-icon {
    z-index: 5;
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
}
.active .item_desc_line {
  color: #30ae64;
}
.item_desc_line::after {
  position: absolute;
  top: 12px;
  left: 5px;
  z-index: 0;
  width: 1px;
  height: 40px;
  content: ' ';
  border-left: 1px dashed #707070;
}
.item_card_H_wrap:last-child .item_desc_line::after {
  content: none;
}
.item_desc {
  display: flex;
  flex: 5;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  &_main {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: Source Han Sans CN;
    font-size: @font-size-small;
    font-weight: 400;
    color: #999999;
    &_name {
      flex: 0 1 120px;
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      white-space: nowrap;
    }
  }
  &_sub {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    font-size: @font-size-small;
    color: @gray;
    &_store {
      flex: 1 1;
      white-space: nowrap;
    }
  }
}
.item_num {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: @font-size-small;
}
.class_skeleton {
  color: #bfbfbf;
  background-color: #bfbfbf;
}
.cr {
  color: #ff3b30;
}
.cg {
  color: #30ae64;
}
</style>
